<template>
  <div class="trademark">
    <img src="../../assets/product/trademark/1.png" class="headerimg" />
    <div class="contertop progress">
      <div class="title">一个商标，为企业发展保驾护航</div>
      <div class="advantagelist">
        <div class="advantagecard">
          <div class="cardtitle">无商标</div>
          <div class="cardinfo">
            <div class="cardline">
              <img src="../../assets/product/trademark/2.png" alt="" />
              <span>风险：容易被抢注、被模仿</span>
            </div>
            <div class="cardline">
              <img src="../../assets/product/trademark/2.png" alt="" />
              <span
                >后果：一旦被认定侵权，之前在各平台积累的客源将会严重流失，且维权之路漫长</span
              >
            </div>
          </div>
        </div>

        <div class="advantagecard">
          <div class="cardtitle">有商标</div>
          <div class="cardinfo">
            <div class="cardline">
              <img src="../../assets/product/trademark/3.png" alt="" />
              <span>零风险，可以专心做好产品和推广</span>
            </div>
            <div class="cardline">
              <img src="../../assets/product/trademark/3.png" alt="" />
              <span>便于客户认牌消费，提升公司业绩</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="information contertop">
      <div class="title">更是品牌的基本组成部分</div>
      <div class="title2">
        区分市场上无品牌的产品服务，让客户对产品服务有更深的品牌认同
      </div>
      <div class="informationimg">
        <img src="../../assets/product/trademark/4.png" alt="" />
      </div>
    </div>

    <div class="certification contertop">
      <div class="title">以下平台入驻的必备资质</div>
      <div class="advantagelist">
        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/trademark/5.png" alt="" />
          </div>
          <div class="cardtext">天猫</div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/trademark/6.png" alt="" />
          </div>
          <div class="cardtext">京东</div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/trademark/7.png" alt="" />
          </div>
          <div class="cardtext">线下超市</div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/trademark/8.png" alt="" />
          </div>
          <div class="cardtext">线下卖场</div>
        </div>
      </div>
    </div>

    <div class="information contertop">
      <div class="title">如何进行商标注册</div>
      <div class="advantagelist" style="align-items: initial">
        <div class="black">
          <div class="blacktitle">自己向商标局提交注册，您需要</div>
          <div class="blacktitle1">
            1、自行查询能否注册<span>2、自行查询近似商标</span>
          </div>
          <div class="blacktitle1">
            3、自行判断注册风险<span>4、自行撰写商标申请</span>
          </div>
          <div class="blacktitle1">
            5、自行确认注册类别<span>6、自行确认初审状态</span>
          </div>
          <div class="blacktitle1">7、自行准备申报材料</div>
        </div>
        <div class="need">
          <div class="needheader">交给我们，您只需要</div>
          <div class="needline">
            <img src="../../assets/product/trademark/9.png" alt="" />
            <span>提交商标名查询</span>
          </div>
          <div class="needline">
            <img src="../../assets/product/trademark/9.png" alt="" />
            <span>提供商标图样和营业执照副本复印件</span>
          </div>
          <div class="needline">
            <img src="../../assets/product/trademark/9.png" alt="" />
            <span>交由我们全权负责</span>
          </div>
        </div>
      </div>
    </div>

    <div class="certification contertop where">
      <div class="title">为什么选择我们</div>
      <div class="advantagelist">
        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/trademark/10.png" alt="" />
          </div>
          <div class="cardtext">快捷申报，高效率</div>
          <div class="cardtext1">最快24小时提交至商标局</div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/trademark/11.png" alt="" />
          </div>
          <div class="cardtext">资深顾问，硬实力</div>
          <div class="cardtext1">资深顾问定制注册方案</div>
          <div class="cardtext1">有效保障注册通过率</div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/trademark/12.png" alt="" />
          </div>
          <div class="cardtext">平台透明，可视化</div>
          <div class="cardtext1">平台全程监控</div>
          <div class="cardtext1">实时追踪注册进度</div>
        </div>
      </div>
    </div>

    <div class="information contertop conterbottom">
      <div class="title">我们服务过的客户</div>
      <div class="informationimg">
        <img src="../../assets/product/trademark/13.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.where {
  .cardtext {
    margin-top: 29px;
    margin-bottom: 14px;
  }
  .cardtext1 {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
  }
}

.information .advantagelist {
  justify-content: space-between;
  width: 60%;
  margin: auto;
  margin-top: 60px;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  .blacktitle {
    margin-bottom: 7px;
  }
  .blacktitle1 {
    margin-top: 30px;
    span {
      margin-left: 40px;
    }
  }

  .needheader {
    width: 281px;
    height: 46px;
    background: #455ce4;
    border-radius: 24px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
  }

  .needline {
    margin-top: 26px;
    img {
      width: 30px;
    }
    span {
      margin-left: 20px;
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
    }
  }
}

.certification .advantagelist {
  justify-content: space-between;
  width: 50%;
  margin: auto;
  margin-top: 60px;
  .advantagecard {
    text-align: center;
    margin-left: 0;
    .cardimg {
      img {
        width: 170px;
      }
    }
    .cardtext {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
    }
  }
}

.progress .advantagelist {
  .advantagecard {
    margin-left: 100px;
    width: 476px;
    height: 211px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
    border-radius: 14px 14px 0px 0px;
    .cardtitle {
      text-align: center;
      margin-top: 30px;
      font-size: 30px;
      font-weight: 500;
      line-height: 42px;
    }
    .cardinfo {
      margin-top: 6px;
      .cardline {
        margin-top: 12px;
        margin-left: 46px;
        img {
          width: 34px;
          vertical-align: middle;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 16px;
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
          width: 330px;
        }
      }
    }
  }
}
</style>